import util from "@bmp-vue/core/api/util";

const controller = "Rrc0021";
export default Object.assign({}, util.GetCrud(controller, [""]), {
    Incluir: function(identdOp, dto) {
        return util.Axios.Post(
            controller + "/operacao" + `/${identdOp}`,
            dto
        );
    },
    IncluirArquivo: function (dto) {
      return util.Axios.Post(
        controller + "/operacao/Importar",
        dto
      );
    },
    GetRespostaSincronaByCodigo: function(codigo) {
        return util.Axios.GetAll(controller + `/${codigo}/RespostaSincrona`)
    },
    GetRespostaRegistroByCodigo: function(codigo) {
        return util.Axios.GetAll(controller + `/${codigo}/RespostaRegistro`)
    }
});
