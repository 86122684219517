import util from '@bmp-vue/core/api/util'

const controller = 'SituacaoPvs'
export default Object.assign({}, util.GetCrud(controller, ['getall', 'get', 'put', 'delete']), {
  GetSituacaoPVs(dto) {
    return util.Axios.GetAll(controller + '/GetSituacaoPVs', dto)
  },
  GetSituacaoPVSDashboard(dto) {
    return util.Axios.GetAll(controller + '/GetSituacaoPVSDashboard', dto)
  }
})


