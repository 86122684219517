import util from '@bmp-vue/core/api/util'

const controller = 'Parceiros'
export default Object.assign({}, util.GetCrud(controller, null), {
    GetAllApiKeysByCodigo: function (codigoParceiro) {
        return util.Axios.GetAll(`${controller}/${codigoParceiro}/ApiKeys`)
    },
    GerarApiKey: function (codigoParceiro) {
        return util.Axios.Post(`${controller}/${codigoParceiro}/ApiKey/Gerar`,
            { codigo: codigoParceiro })
    },
    GetAuth: function (codigoParceiro) {
        return util.Axios.Get(`${controller}/${codigoParceiro}/autenticacao-callback`)
    },
    GetAdesao: function (codigoParceiro) {
        return util.Axios.Get(`${controller}/${codigoParceiro}/adesao`)
    },
    UpdateAdesao: function (codigoParceiro, dto) {
        return util.Axios.Put(`${controller}/${codigoParceiro}/adesao`, '', dto)
    },
    UpdateAutenticacao: function (codigoParceiro, dto) {
        return util.Axios.Put(`${controller}/${codigoParceiro}/autenticacao-callback`, '', dto)
    },
    PostHeader: function (codigoParceiro, dto) {
        return util.Axios.Post(`${controller}/${codigoParceiro}/headers`,
            { codigoParceiro: codigoParceiro, ...dto })
    },
    PostScope: function (codigoParceiro, dto) {
        return util.Axios.Post(`${controller}/${codigoParceiro}/scopes`,
            { codigoParceiro: codigoParceiro, ...dto })
    },
    UpdateHeader: function (codigoParceiro, codigoHeader, dto) {
        return util.Axios.Put(`${controller}/${codigoParceiro}/headers/${codigoHeader}`, '', dto)
    },
    UpdateScope: function (codigoParceiro, codigoScope, dto) {
        return util.Axios.Put(`${controller}/${codigoParceiro}/scopes/${codigoScope}`, '', dto)
    },
    GetAllHeaders: function (codigoParceiro) {
        return util.Axios.GetAll(`${controller}/${codigoParceiro}/headers`)
    },
    GetAllScopes: function (codigoParceiro) {
        return util.Axios.GetAll(`${controller}/${codigoParceiro}/scopes`)
    },
    GetParceirosAtivos: function () {
        return util.Axios.GetAll(`${controller}/ativo`)
    }
})
